import React from 'react';

// import styles from './WhyUs.module.scss';

const WhyUs = () => {
    return (
        <section className="why section" id="why-us">
            <h2 className="section__title why__title">Why Opt for WealthWatch?</h2>
            <div className="why__container container-style grid-style">
                <div className="why__card">
                <i className="ri-newspaper-line why__image"></i>
                <h1 className="why__subtitle">Effortless Invoicing</h1>
                <p className="why__description">
                    Bid farewell to spreadsheet complexities and template headaches.
                    WealthWatch presents a user-friendly interface that empowers you
                    to craft professional invoices in mere minutes. Enter your
                    client's details and let WealthWatch handle the calculations for
                    you.
                </p>
                </div>

                <div className="why__card">
                <i className="ri-brush-4-line why__image"></i>
                <h1 className="why__subtitle">Tailored Customization</h1>
                <p className="why__description">
                    We understand the uniqueness of each business. That's why
                    WealthWatch offers an array of customizable invoice templates
                    that align with your brand's persona. From fonts and color schemes
                    to your company logo, you have the creative control to shape
                    invoices that mirror your brand identity.
                </p>
                </div>

                <div className="why__card">
                <i className="ri-trophy-line why__image"></i>
                <h1 className="why__subtitle">Instant Professionalism</h1>
                <p className="why__description">
                    Make a lasting impression on your clients with sleek and refined
                    invoices that mirror your commitment to excellence. WealthWatch
                    guarantees that each invoice you send radiates professionalism,
                    aiding you in fostering trust and credibility with your clientele.
                </p>
                </div>
            </div>
        </section>
    );
};

export default WhyUs;
