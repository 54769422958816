import React from 'react';

const Features = () => {
    return (
        <section className="feature section" id="feature">
            <div className="feature__container container-style grid-style">
                <div className="feature__data">
                <h2 className="section__title feature__title">
                    We Provide Many Features You Can Use
                </h2>

                <p className="feature__description">
                    You can explore the features that we provide with fun and have
                    their own functions each feature.
                </p>

                <ul className="feature__ul">
                    <li className="feature__li">
                    <div className="icon__container">
                        <i className="ri-check-line feature__icon"></i>
                    </div>
                    <span className="feature__content"
                        >Effortless client management</span
                    >
                    </li>
                    <li className="feature__li">
                    <div className="icon__container">
                        <i className="ri-check-line feature__icon"></i>
                    </div>
                    <span className="feature__content">Detailed line item billing</span>
                    </li>
                    <li className="feature__li">
                    <div className="icon__container">
                        <i className="ri-check-line feature__icon"></i>
                    </div>
                    <span className="feature__content">Automated calculations</span>
                    </li>
                    <li className="feature__li">
                    <div className="icon__container">
                        <i className="ri-check-line feature__icon"></i>
                    </div>
                    <span className="feature__content">Integrated payments</span>
                    </li>
                    <li className="feature__li">
                    <div className="icon__container">
                        <i className="ri-check-line feature__icon"></i>
                    </div>
                    <span className="feature__content">Effortless reporting</span>
                    </li>
                </ul>
                </div>

                <img
                    src="/assets/img/feature-overview.png"
                    alt="feature overview"
                    className="feature__img"
                />
            </div>
        </section>
    );
};

export default Features;
