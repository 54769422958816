import React from 'react';

// import styles from './StartNow.module.scss';

const StartNow = () => {
    return (
        <section className="begin section" id="begin">
            <div className="begin__container container-style grid-style">
                <div className="begin__data">
                <h2 className="section__title">Begin Today!</h2>

                <p className="begin__description">
                    Join the league of astute business proprietors transforming their
                    invoicing process with WealthWatch. Enroll today for a free
                    trial and savor the simplicity, professionalism, and efficiency
                    that our app injects into your business. Streamline your
                    invoicing, concentrate on your core competencies, and let
                    WealthWatch manage the rest!
                </p>

                <a href="/#" className="button">
                    Sign Up for Your Free Trial <i className="ri-arrow-right-line"></i>
                </a>
                </div>

                <img
                    src="/assets/img/begin-register.png"
                    alt="begin register"
                    className="begin__img"
                />
            </div>
        </section>
    );
};

export default StartNow;
