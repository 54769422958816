import React from 'react';

const HeaderContent = () => {
    return (
        <>
            {/* <!--==================== HEADER ====================--> */}
            <header className="header" id="header">
                <nav className="navbar container-style">
                <a href="/" className="navbar__logo"> WealthWatch </a>

                <div className="navbar__menu" id="nav-menu">
                    <ul className="navbar__list">
                        <li className="navbar__item">
                            <a href="/" className="navbar__links active-link">Home</a>
                        </li>
                        <li className="navbar__item">
                            <a href="/#why-us" className="navbar__links">Why Us</a>
                        </li>
                        <li className="navbar__item">
                            <a href="#feature" className="navbar__links">Features</a>
                        </li>
                        <li className="navbar__item">
                            <a href="#plan" className="navbar__links">Pricing</a>
                        </li>
                        <li className="navbar__item">
                            <a href="#faq" className="navbar__links">FAQ</a>
                        </li>
                        <li className="navbar__item">
                            <a href="#contact" className="navbar__links">Contact Us</a>
                        </li>
                        <li className="navbar__item">
                            <a href="/admin" className="navbar__links">Login</a>
                        </li>
                    </ul>

                    {/* <!-- close button --> */}
                    <div className="navbar__close" id="nav-close">
                    <i className="ri-close-line"></i>
                    </div>
                </div>

                <div className="navbar__buttons">
                    {/* <!-- theme change button --> */}
                    <i className="ri-moon-line change-theme" id="theme-button"></i>

                    {/* <!-- toggle button --> */}
                    <div className="navbar__toggle" id="nav-toggle">
                    <i className="ri-menu-3-line"></i>
                    </div>
                </div>
                </nav>
            </header>
        </>
    );
};

export default HeaderContent;