import React from 'react';

import styles from './CryptoMarketCoin.module.scss';
import { useParams, useSearchParams } from 'react-router-dom';
import { GlobalVariables } from '../GlobalVariables';
import { Box } from '@mui/material';
import { ArcElement, Chart, CategoryScale, DoughnutController, LineController, LineElement, LinearScale, PointElement, TimeScale } from 'chart.js';
import OutLabels from 'chartjs-plugin-outlabels4';
import 'chartjs-adapter-date-fns';
import { enGB } from 'date-fns/locale';
import { fetchJsonData } from '../Utils';
import { IChartProps, ICoinsData } from '../Interfaces';

Chart.register(ArcElement, CategoryScale, DoughnutController, LineController, LineElement, LinearScale, PointElement, OutLabels, TimeScale);

const Doughnut: React.FC<IChartProps> = (props) => {
    React.useEffect(() => {
      console.log('created');
      const doughnut = new Chart('myDougnutChart', {
        type: 'doughnut',
        data: {
          labels: props.labels,
          datasets: props.datasets,
        },
        options: {
            scales: {
                x: {
                display: false,
                grid: {
                    display: false
                }
                },
                y: {
                display: false, 
                grid: {
                    display: false
                }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true
                },
                outlabels: {
                    display: true,
                    text: '%l ($%v.2)',
                    borderWidth: 2,
                    lineWidth: 2,
                    padding: 3,
                    textAlign: 'center',
                    // stretch: 45,
                    font: {
                        resizable: true,
                        minSize: 12,
                        maxSize: 18
                    },
                    valuePrecision: 1,
                    percentPrecision: 2,
                },
                // doughnutlabel: {
                //   // backgroundColor: function(context) {
                //   //   return context.dataset.backgroundColor;
                //   // },
                //   borderColor: 'white',
                //   borderRadius: 25,
                //   borderWidth: 2,
                //   color: 'white',
                //   // display: function(context) {
                //   //   var dataset = context.dataset;
                //   //   var count = dataset.data.length;
                //   //   var value = dataset.data[context.dataIndex];
                //   //   return value > count * 1.5;
                //   // },
                //   font: {
                //     weight: 'bold'
                //   },
                //   padding: 6,
                //   formatter: Math.round
                // }
          },
        //   zoomOutPercentage: 60,
          // datasets: {
          //   doughnut: {
          //     zoomOutPercentage: 60
          //   }
          // },
          layout: {
            // autoPadding: true,
            padding: {
              left: 150,
              top: 35,
              right: 150,
              bottom: 35,
            }
          },
          // cutout: '60%',
          // responsive: false,
          // maintainAspectRatio: false
        },
      });
  
      return () => {
        console.log('destroyed');
        doughnut.destroy();
      };
    }, []);
  
    React.useEffect(() => {
      const doughnut = Chart.getChart('myDougnutChart');
      if (doughnut) {
        doughnut.data.datasets = props.datasets;
        doughnut.data.labels = props.labels;
        doughnut.update('none');
      }
    }, [props]);
  
    return <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      height: '450px',
      width: '450px',
      margin: 'auto',
    }}>
      <canvas id="myDougnutChart"></canvas>
    </Box>;
};

const PriceChart: React.FC<IChartProps> = (props) => {
    React.useEffect(() => {
      console.log('created');
      const priceChart = new Chart('myPriceChart', {
        type: 'line',
        data: {
          labels: props.labels,
          datasets: props.datasets,
        },
        options: {
            scales: {
                x: {
                    adapters: {
                        date: {
                            locale: enGB
                        }
                    },
                    type: 'time',
                    time: {
                        unit: 'second'
                    }
                },
            },
            plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  enabled: true
                },
                outlabels: {
                    display: false,
                    text: '%l ($%v.2)',
                    borderWidth: 2,
                    lineWidth: 2,
                    padding: 3,
                    textAlign: 'center',
                    // stretch: 45,
                    font: {
                        resizable: true,
                        minSize: 12,
                        maxSize: 18
                    },
                    valuePrecision: 1,
                    percentPrecision: 2,
                }
            },
            // zoomOutPercentage: 60
            // cutout: '60%',
            // responsive: false,
            // maintainAspectRatio: false
        },
      });
  
      return () => {
        console.log('destroyed');
        priceChart.destroy();
      };
    }, []);
  
    React.useEffect(() => {
      const priceChart = Chart.getChart('myPriceChart');
      if (priceChart) {
        priceChart.data.labels = props.labels;
        priceChart.data.datasets = props.datasets;
        priceChart.update('none');
      }
    }, [props]);
  
    return <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      height: '500px',
      width: '1000px',
      margin: 'auto',
    }}>
      <canvas id="myPriceChart"></canvas>
    </Box>;
};

const CryptoMarketCoinContent = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [refreshState, toggleRefreshState] = React.useReducer(() => Math.random(), 0);
    const forceRefresh = React.useCallback(() => toggleRefreshState(), []);
    const [fetchData, triggerFetchData] = React.useReducer(() => Date.now(), 0);
    const forceFetchData = React.useCallback(() => triggerFetchData(), []);
    const [oldSumValue, setOldSumValue] = React.useState(0.0);
    const [path, setPath] = React.useState('');
    const [sumValue, setSumValue] = React.useState(0.0);
    const [currency, setCurrency] = React.useState('$');
    const [currencyFactor, setCurrencyFactor] = React.useState(1.0);
    const [coinsData, setCoinsData] = React.useState<ICoinsData[]>([]);
    const [inProgress, setInProgress] = React.useState(false);


    /** Get URL parameters from the react router. */
    const params = useParams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        console.log('PARAMS: ', params);
        console.log('searchPARAMS: ', searchParams);
        if (params['*']) {
            /** Deconstruct params. */
            const p = params['*'];
            const coins = p.split('/').map(coin => {
                const parts = coin.split(':');
                if (parts.length > 1) {
                    GlobalVariables.coinsAmount[GlobalVariables.cmcData[parts[0]].id] = parseFloat(parts[1]);
                }
                return parts[0];
            });
            setPath(coins.map(coin => GlobalVariables.cmcData[coin.toUpperCase()].id).join('/'));
        }
        const c = searchParams.get('c');
        if (c) {
            setCurrency(c);
        }
        const cf = searchParams.get('cf');
        if (cf) {
            setCurrencyFactor(parseFloat(cf));
        }

        forceFetchData();
    }, []);

    React.useMemo(() => {
        if (!path || inProgress) {
            return;
        }

        setInProgress(true);
        fetchJsonData(`${GlobalVariables.apiURI}/crypto/prices/${path}`)
            .then(data => {
                setCoinsData(data);
                setInProgress(false);
                setTimeout(forceFetchData, 10000);
            })
            .catch(error => {
                console.error(error.message);
                setInProgress(false);
                setTimeout(forceFetchData, 10000);
            });
    }, [fetchData]);

    const priceChartDatasets = React.useMemo(() => {
        const data = GlobalVariables.priceHistory.map(h => h.value * currencyFactor);
        return [{
            label: 'Value',
            data: data,
            borderColor: 'red',
            backgroundColor: 'argb(0.5, 255, 0, 0)',
            hoverOffset: 4,
            borderWidth: 1,
        }];
    }, [refreshState, coinsData]);

    const priceChartLabels = React.useMemo(() => {
        return GlobalVariables.priceHistory.map(h => h.timestamp);
    }, [refreshState]);

    const chartDatasets = React.useMemo(() => {
        const data = coinsData.map(coin => coin.d.p * currencyFactor * (GlobalVariables.coinsAmount[coin.d.id] ?? 1.0));
        return [{
          label: 'Current',
          data,
          backgroundColor: [
            'rgb(255, 0, 0)',
            'rgb(255, 32, 32)',
            'rgb(128, 132, 99)',
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(255, 128, 0)',
          ],
          hoverOffset: 4
        }];
    }, [coinsData]);
    
    const chartLabels = React.useMemo(() => {
        const labels = coinsData.map(coin => GlobalVariables.cmcData[coin.d.id].symbol);
        return labels;
    }, [coinsData]);

    React.useMemo(() => {
        const sum = coinsData.map(coin => coin.d.p * (GlobalVariables.coinsAmount[coin.d.id] ?? 1.0)).reduce((accumulator, currentValue) => {
            return accumulator + currentValue
        }, 0);
        const ts = coinsData.map(coin => parseInt(coin.t)).reduce((accumulator, currentValue) => {
            return accumulator > currentValue ? accumulator : currentValue;
        }, 0);
        if (sum > 0.0 && sum !== sumValue) {
            GlobalVariables.savePriceHistory({
                timestamp: ts,
                value: sum,
            });
            setOldSumValue(sumValue);
            setSumValue(sum);
            forceRefresh();
        }
    }, [coinsData]);

    return (
        <>
            <div className={styles.coinGroup}>
                {coinsData.map(coin => 
                    <section key={`Coin-${coin.d.id}`} className={[styles.section, coin.d.p1h > 0.0 ? styles.sectionUp : styles.sectionDown].join(' ')} >
                        <div>
                            <img className={styles.coinLogo} src={`${GlobalVariables.imagesURI}s2.coinmarketcap.com/static/img/coins/64x64/${coin.d.id}.png`} loading="lazy" decoding="async" alt={`${GlobalVariables.cmcData[coin.d.id].symbol} logo`} />
                            <div className={styles.coinName}>
                                <span>{`${GlobalVariables.cmcData[coin.d.id].name} price`}</span><br />
                                <span>{GlobalVariables.cmcData[coin.d.id].symbol} ({coin.d.p1h.toFixed(2)}% (1h))</span>
                            </div>
                        </div>
                        <span className={styles.coinPrice}>{(coin.d.p * currencyFactor).toFixed(coin.d.p < 1.0 ? 4 : 2)}{currency}</span>
                        <img className={coin.d.p1h > 0.0 ? styles.isUp : styles.isDown} src={`${GlobalVariables.imagesURI}s3.coinmarketcap.com/generated/sparklines/web/7d/2781/${coin.d.id}.svg`} alt="bitcoin-7d-price-graph" loading="lazy"></img>
                    </section>
                )}
            </div>
            <div className={styles.coinCharts}>
                <section className={styles.chartSection} >
                    <Box sx={{
                        color: 'black',
                        margin: 'auto',
                        textAlign: 'center',
                        alignContent: 'center',
                        fontSize: '32px',
                        fontWeight: 'bold'
                    }}>{(sumValue * currencyFactor).toFixed(2)}{currency}</Box>
                    <Doughnut labels={chartLabels} datasets={chartDatasets} />
                </section>
                <section className={styles.chartSection2} >
                    <PriceChart labels={priceChartLabels} datasets={priceChartDatasets} /> 
                </section>
            </div>
        </>
    );
};

export default CryptoMarketCoinContent;