import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainContent from './Components/MainContent';
import Home from './Components/Home';
import NoPage from './Components/NoPage';
import Market from './Components/Market';
import CryptoMarket from './Components/CryptoMarket';
import CryptoMarketCoin from './Components/CryptoMarketCoin';
import Footer from './Components/Footer';
import Header from './Components/Header';
import CryptoWalletEth from './Components/CryptoWalletEth';

function App() {
  return (
    <BrowserRouter>
      <Header />

      {/* <!--==================== MAIN ====================--> */}
      <main className="main">
        <Routes>
          <Route index element={<MainContent language='en' />} />
          <Route path="/home" element={<Home />} />
          <Route path="/test" element={<Home />} />
          <Route path="/blogs" element={<Home />} />
          <Route path="/blogs" element={<Home />} />
          <Route path="/contact" element={<Home />} />
          <Route path="/market" element={<Market />} />
          <Route path="/market/crypto" element={<CryptoMarket />} />
          <Route path="/market/crypto/*" element={<CryptoMarketCoin />} />
          {/* <Route path="/market/crypto" element={<CryptoMarket />} /> */}
          <Route path="/crypto/ethWallet/*" element={<CryptoWalletEth />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </main>
      
      <Footer />
    </BrowserRouter>
  );
}

export default App;                          
