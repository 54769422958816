import React from 'react';
import SubscriptionPlans from './Main/SubscriptionPlans';
import FAQ from './Main/FAQ';
import ContactUs from './Main/ContactUs';
import Features from './Main/Features';
import StartNow from './Main/StartNow';
import WhyUs from './Main/WhyUs';

interface IMainContentProps {
    language: string;
}

const MainContent = (props: IMainContentProps) => {
    return (
        <>
            {/* <!--==================== HOME ====================--> */}
            <section className="home section" id="home">
                <div style={{
                    backgroundColor: 'red',
                    color: 'whitesmoke',
                    fontWeight: 'bold',
                    left: '-31px',
                    position: 'fixed',
                    textAlign: 'center',
                    top: '8px',
                    transform: 'rotateY(0deg) rotate(-45deg)',
                    width: '100px',
                    zIndex: 10000,
                }}>
                    BETA
                </div>
                <div style={{
                    backgroundColor: 'red',
                    bottom: '20px',
                    color: 'whitesmoke',
                    fontWeight: 'bold',
                    position: 'fixed',
                    right: '-56px',
                    textAlign: 'center',
                    transform: 'rotateY(0deg) rotate(-45deg)',
                    width: '200px',
                    zIndex: 10000,
                }}>
                    WORK IN<br />PROGRESS!!!
                </div>
                <div className="home__container container-style grid-style">
                    <img
                        src="/assets/img/home-invoice.png"
                        alt="invoice"
                        className="home__img"
                    />

                    <div className="home__data">
                    <h1 className="home__title">
                        Welcome to WealthWatch:

                        <div className="home__subtitle">
                        Streamline Your Wealth Monitoring
                        </div>
                    </h1>

                    <p className="home__description">
                        Say goodbye to the hassle of manual invoice creation.
                        WealthWatch is here to simplify your invoicing tasks and keep
                        your business operations seamless.
                    </p>

                    <a href="#plan" className="button">
                        Get Started <i className="ri-arrow-right-line"></i>
                    </a>
                    </div>
                </div>

                <img
                    src="/assets/img/base-cover.png"
                    alt="base cover"
                    className="home__base"
                />
            </section>

            <WhyUs />
            <Features />
            <SubscriptionPlans />
            <FAQ />
            <StartNow />
            <ContactUs />
        </>
    );
};

export default MainContent;