import React from 'react';

// import styles from './SubscriptionPlans.module.scss';

const SubcriptionPlans = () => {
    return (
        <section className="plan section" id="plan">
            <h2 className="section__title">
                Find The Plan That Suits <span className="plan__highlight">Your Needs</span>
            </h2>

            <label className="switch">
                <input id="monthlyCheckbox" type="checkbox" />
                <span className="slider"></span>
                <span className="option-2" id="monthlyOption">Monthly</span>
                <span className="option-1" id="annualOption">Yearly</span>
            </label>

            <div className="currency__selector">
                {/* <div className="button__selector__wrapper"><button id="currencyButton[0]" className="currency__button" onClick={recalculateFor('HKD')}>HKD</button></div>
                <div className="button__selector__wrapper"><button id="currencyButton[1]" className="currency__button-invert" onClick={recalculateFor('USD')}>USD</button></div>
                <div className="button__selector__wrapper"><button id="currencyButton[2]" className="currency__button-invert" onClick={recalculateFor('EUR')}>EUR</button></div> */}
            </div>

            <div className="plan__container container-style grid-style" id="annually">
                <article className="plan__card">
                <div className="plan__header">
                    <h3 className="plan__name">Free</h3>
                    <span className="plan__description">
                    Perfect for freelancers and small businesses getting started with streamlined invoicing.
                    </span>
                </div>
                <div className="plan__content">
                    <div className="plan__price">Free <br /><span id="annualPlanCurrency">Billed at HK$ 0 /month</span></div>
                    <ul className="plan__ul">
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Create up to 10 invoices per month.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Access to basic customizable templates.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Email delivery of invoices to clients.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Basic client management for easy invoicing.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Limited real-time tracking of invoice status.</span>
                    </li>
                    </ul>
                </div>
                <div className="button__wrapper">
                    <a href="/admin"><button className="plan__button">Select Annual Plan</button></a>
                </div>
                </article>
                <article className="plan__card">
                <div className="plan__header">
                    <h3 className="plan__name">Starter</h3>
                    <span className="plan__description">
                    Ideal for growing businesses seeking comprehensive invoicing and payment solutions.
                    </span>
                </div>
                <div className="plan__content">
                    <div id="annualPlan[0]" className="plan__price">HK$ 140<span>/month<br />HK$ 1680 billed annually</span></div>
                    <ul className="plan__ul">
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Create up to 50 invoices per month.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Premium customizable templates to match your branding.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Email and print delivery options for invoices.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Advanced client management with contact storage.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Real-time tracking of invoice status, including views and payments.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Integrated payment gateways for online payments.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Detailed financial reporting and insights.</span>
                    </li>
                    </ul>
                </div>
                <div className="button__wrapper">
                    <a href="/admin"><button className="plan__button">Select Annual Plan</button></a>
                </div>
                </article>
                <article className="plan__card invert">
                <div className="plan__header">
                    <h3 className="plan__name invert">Professional</h3>
                    <span className="plan__description">Suitable for businesses with a growing client base and invoicing demands.</span>
                </div>
                <div className="plan__content">
                    <div id="annualPlan[1]" className="plan__price invert">HK$ 431.67<span>/month<br />HK$ 5180 billed annually</span></div>
                    <ul className="plan__ul">
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Unlimited invoice creation.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Premium customizable templates to match your branding.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Email and print delivery options for invoices.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Advanced client management with contact storage.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Real-time tracking of invoice status, including views and payments.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Integrated payment gateways for online payments.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Detailed financial reporting and insights.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Multi-user collaboration and team access.</span>
                    </li>
                    </ul>
                </div>
                <div className="button__wrapper">
                    <a href="/admin"><button className="plan__button-invert">Select Annual Plan</button></a>
                </div>
                </article>
                <article className="plan__card">
                <div className="plan__header">
                    <h3 className="plan__name">Business</h3>
                    <span className="plan__description">Geared towards established businesses with higher invoicing volumes.</span>
                </div>
                <div className="plan__content">
                    <div id="annualPlan[2]" className="plan__price">HK$ 1,095<span>/month<br />HK$ 13,140 billed annually</span></div>
                    <ul className="plan__ul">
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Unlimited invoice creation.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Premium customizable templates to match your branding.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Email and print delivery options for invoices.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Advanced client management with contact storage.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Real-time tracking of invoice status, including views and
                        payments.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Integrated payment gateways for online payments.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Detailed financial reporting and insights.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Multi-user collaboration and team access.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Priority customer support for assistance.</span
                        >
                    </li>
                    </ul>
                </div>
                <div className="button__wrapper">
                    <a href="/admin"><button className="plan__button">Select Annual Plan</button></a>
                </div>
                </article>
            </div>

            <div className="plan__container container-style grid-style" id="monthly">
                <article className="plan__card">
                <div className="plan__header">
                    <h3 className="plan__name">Free</h3>
                    <span className="plan__description"
                    >Perfect for freelancers and small businesses getting started
                    with streamlined invoicing.</span
                    >
                </div>
                <div className="plan__content">
                    <div className="plan__price">Free <br /></div>
                    <ul className="plan__ul">
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Create up to 10 invoices per month.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Access to basic customizable templates.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Email delivery of invoices to clients.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Basic client management for easy invoicing.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Limited real-time tracking of invoice status.</span
                        >
                    </li>
                    </ul>
                </div>
                <div className="button__wrapper">
                    <button className="plan__button">Select Monthly Plan</button>
                </div>
                </article>
                <article className="plan__card">
                <div className="plan__header">
                    <h3 className="plan__name">Starter</h3>
                    <span className="plan__description"
                    >Ideal for growing businesses seeking comprehensive invoicing
                    and payment solutions.</span
                    >
                </div>
                <div className="plan__content">
                    <div id="monthly__plan[0]" className="plan__price">HK$ 168 <span>/month</span></div>
                    <ul className="plan__ul">
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Create up to 50 invoices per month.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Premium customizable templates to match your
                        branding.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Email and print delivery options for invoices.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Advanced client management with contact storage.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Real-time tracking of invoice status, including views and
                        payments.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Integrated payment gateways for online payments.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Detailed financial reporting and insights.</span
                        >
                    </li>
                    </ul>
                </div>
                <div className="button__wrapper">
                    <button className="plan__button">Select Monthly Plan</button>
                </div>
                </article>
                <article className="plan__card invert">
                <div className="plan__header">
                    <h3 className="plan__name invert">Professional</h3>
                    <span className="plan__description"
                    >Suitable for businesses with a growing client base and
                    invoicing demands.</span
                    >
                </div>
                <div className="plan__content">
                    <div id="monthly__plan[1]" className="plan__price invert">HK$ 518 <span>/month</span></div>
                    <ul className="plan__ul">
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Unlimited invoice creation.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Premium customizable templates to match your
                        branding.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Email and print delivery options for invoices.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Advanced client management with contact storage.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Real-time tracking of invoice status, including views and
                        payments.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Integrated payment gateways for online payments.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Detailed financial reporting and insights.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Multi-user collaboration and team access.</span
                        >
                    </li>
                    </ul>
                </div>
                <div className="button__wrapper">
                    <button className="plan__button-invert">Select Monthly Plan</button>
                </div>
                </article>
                <article className="plan__card">
                <div className="plan__header">
                    <h3 className="plan__name">Business</h3>
                    <span className="plan__description"
                    >Geared towards established businesses with higher invoicing
                    volumes.</span
                    >
                </div>
                <div className="plan__content">
                    <div id="monthly__plan[2]" className="plan__price">HK$ 1,314 <span>/month</span></div>
                    <ul className="plan__ul">
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item">Unlimited invoice creation.</span>
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Premium customizable templates to match your
                        branding.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Email and print delivery options for invoices.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Advanced client management with contact storage.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Real-time tracking of invoice status, including views and
                        payments.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Integrated payment gateways for online payments.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Detailed financial reporting and insights.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Multi-user collaboration and team access.</span
                        >
                    </li>
                    <li className="plan__li">
                        <div className="icon__container">
                        <i className="ri-check-line plan__icon"></i>
                        </div>
                        <span className="plan__item"
                        >Priority customer support for assistance.</span
                        >
                    </li>
                    </ul>
                </div>
                <div className="button__wrapper">
                    <button className="plan__button">Select Monthly Plan</button>
                </div>
                </article>
            </div>

            {/* <span style="margin-top: 2rem; text-align: center; padding: 0 2rem;"> */}
                Need a custom solution for our organization <a href="/">Contact Us Here</a>
            {/* </span> */}
            <span><b>All prices are in HongKong dollars</b></span>
        </section>
    );
};

export default SubcriptionPlans;
