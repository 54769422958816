import React from 'react';

// import styles from './FAQ.module.scss';

const SubcriptionPlans = () => {
    return (
        <section className="faq section" id="faq">
            <div className="faq__container container-style grid-style">
                <div className="faq__data">
                <h2 className="section__title faq__title">
                    Frequently Asked Questions
                </h2>

                <p className="faq__description">
                    If you have any additional questions or need further assistance,
                    feel free to contact our support team. We're here to help you
                    every step of the way!
                </p>

                <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                        >
                        What is WealthWatch?
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                    >
                        <div className="accordion-body">
                        <strong>WealthWatch</strong> is a user-friendly invoice
                        generation app designed to simplify and streamline your
                        invoicing process. It offers a range of features to help you
                        create, manage, and track professional invoices
                        effortlessly.
                        </div>
                    </div>
                    </div>
                    <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                        >
                        What pricing plans does WealthWatch offer?
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse"
                    >
                        <div className="accordion-body">
                        <strong>WealthWatch</strong> offers three pricing plans:
                        <b>"Basic"</b>, <b>"Pro"</b>, and <b>"Enterprise"</b>. Each
                        plan comes with unique features tailored to meet different
                        business needs. You can choose between monthly and annual
                        billing options to find the plan that fits your
                        requirements.
                        </div>
                    </div>
                    </div>
                    <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                        >
                        Can I upgrade or downgrade my plan?
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse"
                    >
                        <div className="accordion-body">
                        Yes, you can upgrade or downgrade your plan at any time. We
                        understand that your business needs may change, and we're
                        here to accommodate your preferences.
                        </div>
                    </div>
                    </div>
                    <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseFour"
                        >
                        How secure is WealthWatch?
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse"
                    >
                        <div className="accordion-body">
                        Security is our top priority. WealthWatch employs advanced
                        encryption and security measures to protect your data. We
                        ensure that your sensitive information remains confidential
                        and secure.
                        </div>
                    </div>
                    </div>
                    <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseFive"
                        >
                        Does WealthWatch support multiple currencies and
                        languages?
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse"
                    >
                        <div className="accordion-body">
                        Yes, WealthWatch supports multiple currencies and
                        languages. This feature is particularly helpful if you have
                        an international clientele or operate in diverse markets.
                        </div>
                    </div>
                    </div>
                    <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseSix"
                        >
                        Can I customize the look of my invoices?
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse"
                    >
                        <div className="accordion-body">
                        Absolutely. WealthWatch provides premium customizable
                        templates, allowing you to align your invoices with your
                        brand's identity. You can add your logo, adjust color
                        schemes, and choose fonts to create a professional and
                        personalized invoice.
                        </div>
                    </div>
                    </div>
                    <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseSeven"
                        >
                        How does the real-time tracking feature work?
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse"
                    >
                        <div className="accordion-body">
                        Our real-time tracking feature keeps you informed about the
                        status of your invoices. You'll receive notifications when
                        your clients view, pay, or have overdue invoices. This helps
                        you stay on top of your finances and manage your business
                        effectively.
                        </div>
                    </div>
                    </div>
                    <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseEight"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseEight"
                        >
                        Can I accept online payments through WealthWatch?
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse"
                    >
                        <div className="accordion-body">
                        Yes, WealthWatch supports integrated payment gateways,
                        making it convenient for your clients to settle their
                        invoices online. This feature accelerates payment processing
                        and improves your cash flow.
                        </div>
                    </div>
                    </div>
                    <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseNine"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseNine"
                        >
                        Is customer support available if I have questions or issues?
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseNine"
                        className="accordion-collapse collapse"
                    >
                        <div className="accordion-body">
                        Absolutely. We provide customer support to assist you with
                        any questions or issues you may encounter. Our knowledgeable
                        support team is here to ensure your experience with
                        WealthWatch is smooth and hassle-free.
                        </div>
                    </div>
                    </div>
                    <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTen"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTen"
                        >
                        How can I get started with WealthWatch?
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse"
                    >
                        <div className="accordion-body">
                        Getting started with WealthWatch is simple. Choose the
                        pricing plan that best suits your needs, sign up for a free
                        trial, and experience the benefits of streamlined invoicing.
                        Create an account, customize your invoices, and start
                        managing your finances more efficiently.
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
};

export default SubcriptionPlans;
