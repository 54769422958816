import React from 'react';

// import styles from './ContactUs.module.scss';

const ContactUs = () => {
    return (
        <section className="contact section" id="contact">
            <div className="contact__container container-style grid-style">
                <div className="contact__data">
                <h2 className="section__title contact__title">
                    Contact Us
                </h2>

                <p className="contact__description">
                    Have questions or need assistance? We're here to help! Reach out to our friendly support team for prompt assistance and answers to your inquiries.
                </p>

                <form id="contact-form" action="https://api.onlineinvoice.me/recaptcha" className="contact__form">
                    <div className="contact__input">
                    {/* <label for="fullName">Name</label> */}
                    <input name="fullName" id="fullName" type="text" placeholder="Input your name" required />
                    </div>
                    <div className="contact__inputs">
                    <div className="contact__input">
                        {/* <label for="email">Email</label> */}
                        <input name="email" id="email" type="text" placeholder="Input your email" required />
                    </div>
                    <div className="contact__input">
                        {/* <label for="phone">Phone Number</label> */}
                        <input name="phone" id="phone" type="number" placeholder="Input your phone number" required />
                    </div>
                    </div>
                    <div className="contact__input">
                    {/* <label for="message">Message</label> */}
                    <textarea name="message" id="message" cols={30} rows={5} placeholder="Tell us your message..."></textarea>
                    </div>
                    <button
                        type="submit"
                        className="contact__button g-recaptcha" 
                        data-sitekey="6LepJ9YnAAAAAJjIfhCZ3EDZt3MC1KBwuRlXNP1J" 
                        data-callback='onSubmit' 
                        data-action='submit'>
                        Send Message
                    </button>
                </form>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
