import React from 'react';

const FooterContent = () => {
    return (
        <>
            {/* <!--==================== FOOTER ====================--> */}
            <footer className="footer-style">
                <div className="footer__container container-style grid-style">
                <div className="footer__left">
                    <a href="/" className="footer__logo"> WealthWatch </a>

                    <p className="footer__description">
                    Experience hassle-free invoicing. <br />Try WealthWatch today!
                    </p>
                </div>

                <div className="footer__content">
                    <div>
                    <h3 className="footer__title">Main Menu</h3>
                    <ul className="footer__links">
                        <li>
                            <a href="/#" className="footer__link">About</a>
                        </li>

                        <li>
                            <a href="/#" className="footer__link">Menus</a>
                        </li>

                        <li>
                            <a href="/#" className="footer__link">Offer</a>
                        </li>

                        <li>
                            <a href="/#" className="footer__link">Events</a>
                        </li>
                    </ul>
                    </div>

                    <div>
                    <h3 className="footer__title">Information</h3>
                    <ul className="footer__links">
                        <li>
                        <a href="/#" className="footer__link">Contact</a>
                        </li>

                        <li>
                        <a href="/#" className="footer__link">Blogs</a>
                        </li>

                        <li>
                        <a href="/#" className="footer__link">Videos</a>
                        </li>

                        <li>
                        <a href="/#" className="footer__link">Community</a>
                        </li>
                    </ul>
                    </div>

                    <div>
                    <h3 className="footer__title">Address</h3>
                    <ul className="footer__links">
                        <li className="footer__information">
                        Yogyakarta 1234 <br />
                        Daerah Istimewa Yogyakarta, Indonesia
                        </li>

                        <li className="footer__information">9AM - 11PM</li>
                    </ul>
                    </div>

                    <div>
                    <h3 className="footer__title">Social Media</h3>
                    <ul className="footer__social">
                        <a
                            href="https://www.facebook.com/"
                            target="_blank"
                            className="footer__social-link"
                            rel="noreferrer"
                        >
                        <i className="ri-facebook-circle-fill"></i>
                        </a>

                        <a
                            href="https://www.instagram.com/"
                            target="_blank"
                            className="footer__social-link"
                            rel="noreferrer"
                        >
                        <i className="ri-instagram-fill"></i>
                        </a>

                        <a
                            href="https://twitter.com/"
                            target="_blank"
                            className="footer__social-link"
                            rel="noreferrer"
                        >
                        <i className="ri-twitter-fill"></i>
                        </a>
                    </ul>
                    </div>
                </div>
                </div>

                <div className="footer__info container-style">
                <div className="footer__card">
                    <img src="/assets/img/footer-card-1.png" alt="footer card 1" />
                    <img src="/assets/img/footer-card-2.png" alt="footer card 2" />
                    <img src="/assets/img/footer-card-3.png" alt="footer card 3" />
                    <img src="/assets/img/footer-card-4.png" alt="footer card 4" />
                </div>

                <span className="footer__copy">
                    Copyright Rocinante Systems Limited. All rights reserved
                </span>
                </div>
            </footer>

            {/* <!--========== SCROLL UP ==========--> */}
            <a href="/#" className="scrollup" id="scroll-up">
                <i className="ri-arrow-up-line"></i>
            </a>

            {/* <!----===============SCROLLREVEAL ===============--> */}
            <script src="/assets/js/scrollreveal.min.js"></script>

            {/* <!--=============== MAIN JS ===============--> */}
            <script src="/assets/js/main.js"></script>

            {/* <!-- ================= BOOTSTRAP ============== --> */}
            <script
                src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js"
                integrity="sha384-HwwvtgBNo3bZJJLYd8oVXjrBZt8cqVSpeBNS5n7C8IVInixGAoxmnlMuBnhbgrkm"
                crossOrigin="anonymous"></script>
        </>
    );
};

export default FooterContent;