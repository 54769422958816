import cmcData from './config/cmc.json';

interface ICMCCoinPlatform {
    id: number;
    name: string;
    symbol: string;
    slug: string;
    token_address: string;
}

interface ICMCCoin {
    id: number;
    rank: number;
    name: string;
    symbol: string;
    slug: string;
    is_active: number;
    first_historical_data: string;
    last_historical_data: string;
    platform: ICMCCoinPlatform | null;
}

interface IPriceHistory {
    timestamp: number;
    value: number;
}

export class GlobalVariables {
    static readonly apiURI = process.env.REACT_APP_API_URI ?? 'https://api.wealthwatch.me'
    static readonly imagesURI = process.env.REACT_APP_IMAGES_URI ?? 'https://';
    static readonly coinsAmount: { [id: string]: number } = {};

    private static readonly _priceHistoryPurgeTTL = 24 * 3600 * 1000; //24h
    private static readonly _priceHistoryDisplayTTL = 1 * 3600 * 1000; //1h
    private static _priceHistory: IPriceHistory[] = [];

    static get cmcData(): { [id: string]: ICMCCoin } {
        return cmcData;
    }

    static get priceHistory(): IPriceHistory[] {
        const ageLimit = Date.now() - this._priceHistoryDisplayTTL;
        return this._priceHistory.filter(d => {
            return d.timestamp >= ageLimit;
        });
    }

    static addCoinAmount(coinId: string, amount: number) {
        if (!GlobalVariables.cmcData[coinId]) {
            console.log('Shit coin alert: ', coinId);
            return;
        }
        const id = GlobalVariables.cmcData[coinId].id;
        if (!GlobalVariables.coinsAmount[id]) {
            GlobalVariables.coinsAmount[id] = 0;
        }
        GlobalVariables.coinsAmount[id] += amount;
    }

    static savePriceHistory(priceHistory: IPriceHistory) {
        const ageLimit = Date.now() - this._priceHistoryPurgeTTL;
        this._priceHistory = this._priceHistory.filter(d => d.timestamp >= ageLimit);
        this._priceHistory.push(priceHistory);
    }
}
